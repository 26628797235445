import React from 'react';
import { Button, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import ThemeService from '../services/ThemeService';

interface Props {
  onPress: () => void;
  label: string;
  progress?: number;
  showSkip?: boolean;
  onPressSkip?: () => void;
}

export const ProgressButton = ({ onPress, label, showSkip, progress, onPressSkip }: Props) => {
  return (
    <View style={styles.container}>
      {showSkip && (
        <TouchableOpacity onPress={onPressSkip} style={styles.skipContainer}>
          <Text style={styles.skip}>Überspringen</Text>
        </TouchableOpacity>
      )}
      {!showSkip && progress !== undefined && (
        <View style={styles.progress}>
          <View style={StyleSheet.compose(styles.progressInner, { width: `${progress > 0 ? progress : 1}%` } as any)} />
        </View>
      )}

      <TouchableOpacity
        onPress={onPress}
        style={progress !== undefined || showSkip ? styles.labelContainer : styles.labelContainerStandalone}
      >
        <Text style={styles.label}>{label}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: ThemeService.getTileColor(),
    borderRadius: 52,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  labelContainerStandalone: {
    padding: 16,
    alignItems: 'flex-end',
    width: '100%',
  },
  labelContainer: {
    padding: 16,
  },
  label: {
    fontSize: 16,
    fontFamily: 'Nunito_700Bold',
    color: ThemeService.getDefaultColor(),
  },
  skipContainer: {
    padding: 16,
  },
  skip: {
    fontSize: 12,
    opacity: 0.8,
    color: ThemeService.getDefaultColor(),
  },
  progress: {
    flex: 1,
    marginHorizontal: 15,
    backgroundColor: ThemeService.getBackgroundColor(),
    height: 4,
    borderRadius: 20,
  },
  progressInner: {
    backgroundColor: ThemeService.getPrimaryColor(),
    height: 4,
    borderRadius: 20,
  },
});
