import { useEffect, useRef, useState } from 'react';

export default class Helper {
  public static useDebounce<T>(value: T, delay?: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
      const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  public static timeToMinutes(time: [number, number]): number {
    return time[0] * 60 + time[1];
  }

  public static minutesToTime(min: number): [number, number] {
    let hours = Math.floor(min / 60);
    let minutes = min % 60;

    if (minutes > 59) {
      hours = hours + Math.floor(minutes / 60);
      minutes = minutes - Math.floor(minutes / 60) * 60;
    }

    if (hours > 23) {
      hours = hours - 24;
    }

    return [hours, minutes];
  }

  public static addMinutesToTime(time: [number, number], addMinutes: number): [number, number] {
    return Helper.minutesToTime(Helper.timeToMinutes(time) + addMinutes);
  }

  public static subtractMinutesFromTime(time: [number, number], subtractMinutes: number): [number, number] {
    return Helper.minutesToTime(Helper.timeToMinutes(time) - subtractMinutes);
  }

  public static getDurationInMinutes(startTime: [number, number], endTime: [number, number]): number {
    if (startTime[0] > endTime[0]) {
      // end time is next day

      const minutesOnFirstDay = 1440 - Helper.timeToMinutes(startTime);
      const minutesOnSecondDay = Helper.timeToMinutes(endTime);

      return minutesOnFirstDay + minutesOnSecondDay;
    } else {
      // start and end time are same day
      return Helper.timeToMinutes(endTime) - Helper.timeToMinutes(startTime);
    }
  }

  public static useStateWithCallback<T>(initialValue: T): [T, (newValue: T, callback?: () => void) => void] {
    const callbackRef = useRef<any>(null);

    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      if (callbackRef.current) {
        callbackRef.current(value);
        callbackRef.current = null;
      }
    }, [value]);

    const setValueWithCallback = (newValue: T, callback?: () => void) => {
      if (!callback) callback = () => {};
      callbackRef.current = callback;
      return setValue(newValue);
    };

    return [value, setValueWithCallback];
  }
}
