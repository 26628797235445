import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ThemeService from '../services/ThemeService';

interface Props {
  text: string;
}

export const PreHeadline = ({ text }: Props) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  text: {
    fontSize: 18,
    fontFamily: 'Nunito_400Regular',
    color: ThemeService.getDefaultColor(),
  },
});
