import React, { useEffect, useRef, useState } from 'react';
import { Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import ThemeService from '../services/ThemeService';
import { LinearGradient } from 'expo-linear-gradient';
import Helper from '../services/Helper';

interface Props {
  hours?: number;
  minutes?: number;
  onChangeHours?: (val: number) => void;
  onChangeMinutes?: (val: number) => void;
}

export const TimePicker = (props: Props) => {
  const hourScrollRef = useRef<ScrollView>(null);
  const minuteScrollRef = useRef<ScrollView>(null);

  const [hours, setHours] = useState<number>(props.hours || 0);
  const [minutes, setMinutes] = useState<number>(props.minutes || 0);

  const [currentScrollPositionHours, setCurrentScrollPositionHours] = useState<number>();
  const [currentScrollPositionMinutes, setCurrentScrollPositionMinutes] = useState<number>();

  const debouncedScrollPositionHours = Helper.useDebounce<number | undefined>(currentScrollPositionHours, 200);
  const debouncedScrollPositionMinutes = Helper.useDebounce<number | undefined>(currentScrollPositionMinutes, 200);

  useEffect(() => {
    if (hours != props.hours && props.onChangeHours) props.onChangeHours(hours);
    if (minutes != props.minutes && props.onChangeMinutes) props.onChangeMinutes(minutes);
  }, [hours, minutes]);

  useEffect(() => {
    if (debouncedScrollPositionHours === undefined) return;

    let val = Math.round(debouncedScrollPositionHours / 40);
    if (val < 0) val = 0;
    if (val > 23) val = 23;

    setHours(val);

    if (Platform.OS === 'web') {
      setTimeout(() => {
        hourScrollRef.current?.scrollTo({ y: val * 40, animated: true });
      }, 100);
    }
  }, [debouncedScrollPositionHours]);

  useEffect(() => {
    if (debouncedScrollPositionMinutes === undefined) return;

    let val = Math.round(debouncedScrollPositionMinutes / 40);
    if (val < 0) val = 0;
    if (val > 59) val = 59;

    setMinutes(val);

    if (Platform.OS === 'web') {
      minuteScrollRef.current?.scrollTo({ y: val * 40, animated: true });
    }
  }, [debouncedScrollPositionMinutes]);

  useEffect(() => {
    if (props.hours !== undefined) {
      hourScrollRef.current?.scrollTo({ y: props.hours * 40, animated: false });
    }
  }, [props.hours]);

  useEffect(() => {
    if (props.minutes !== undefined) {
      minuteScrollRef.current?.scrollTo({ y: props.minutes * 40, animated: false });
    }
  }, [props.minutes]);

  const hourOptions = [];
  for (let i = 0; i < 24; i++) {
    hourOptions.push(i < 10 ? `0${i}` : i);
  }
  hourOptions.push('');

  const minuteOptions = [];
  for (let i = 0; i < 60; i++) {
    minuteOptions.push(i < 10 ? `0${i}` : i);
  }
  minuteOptions.push('');

  return (
    <View style={styles.outerContainer}>
      <View style={styles.container}>
        <ScrollView
          style={styles.scrollContainer}
          showsVerticalScrollIndicator={false}
          snapToInterval={40}
          ref={hourScrollRef}
          scrollEventThrottle={16}
          onScroll={(event) => {
            setCurrentScrollPositionHours(event.nativeEvent.contentOffset.y);
          }}
          onMomentumScrollEnd={(event) => setCurrentScrollPositionHours(event.nativeEvent.contentOffset.y)}
        >
          {hourOptions.map((hour, key) => (
            <View style={StyleSheet.compose(styles.timeContainer, styles.hours as any)} key={key}>
              <Text style={styles.time}>{hour}</Text>
            </View>
          ))}
        </ScrollView>
        <View>
          <View style={styles.timeContainer}>
            <Text style={styles.time}>:</Text>
          </View>
        </View>
        <ScrollView
          style={styles.scrollContainer}
          showsVerticalScrollIndicator={false}
          snapToInterval={40}
          ref={minuteScrollRef}
          scrollEventThrottle={16}
          onScroll={(event) => {
            setCurrentScrollPositionMinutes(event.nativeEvent.contentOffset.y);
          }}
          onMomentumScrollEnd={(event) => setCurrentScrollPositionMinutes(event.nativeEvent.contentOffset.y)}
        >
          {minuteOptions.map((minute, key) => (
            <View style={styles.timeContainer} key={key}>
              <Text style={styles.time}>{minute}</Text>
            </View>
          ))}
        </ScrollView>

        <LinearGradient
          colors={[ThemeService.getBackgroundColorAsRgba(0), ThemeService.getBackgroundColorAsRgba(1)]}
          style={styles.gradientTop}
          start={{ x: 0, y: 1 }}
          end={{ x: 0, y: 0 }}
        />

        <LinearGradient
          colors={[ThemeService.getBackgroundColorAsRgba(1), ThemeService.getBackgroundColorAsRgba(0)]}
          style={styles.gradientBottom}
          start={{ x: 0, y: 1 }}
          end={{ x: 0, y: 0 }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    width: '100%',
    alignItems: 'center',
  },
  container: {
    width: 180,
    height: 80,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  gradientTop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 15,
  },
  gradientBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 15,
  },
  scrollContainer: {
    width: 40,
    height: 80,
    paddingTop: 22,
  },
  time: {
    color: ThemeService.getPrimaryColor(),
    fontSize: 36,
    fontFamily: 'Nunito_400Regular',
    letterSpacing: 0.5,
    lineHeight: 40,
  },
  timeContainer: {
    height: 40,
    marginHorizontal: 3,
  },
  hours: {
    alignItems: 'flex-end',
  },
});
