import { useTranslation } from 'react-i18next';
import QuestionsSection from '../../interfaces/QuestionsSection';
import useQuestions from './useQuestions';

export default function useSections() {
  const { t } = useTranslation();

  const questions = useQuestions();

  const sections: QuestionsSection[] = [
    {
      id: 'workDay',
      title: t('mctq.questions.workDays'),
      questions,
    },
    {
      id: 'freeDay',
      title: t('mctq.questions.freeDays'),
      questions,
    },
  ];

  return sections;
}
