import React from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/Layout';
import { Logo } from '../components/Logo';
import { Headline } from '../components/Headline';
import { SubHeadline } from '../components/SubHeadline';
import { Text } from '../components/Text';
import { ProgressButton } from '../components/ProgressButton';
import { Container } from '../components/Container';
import { Footer } from '../components/Footer';
import { Distribution } from '../components/Distribution';
import { LanguageHeader } from '../components/LanguageHeader';

type Props = NativeStackScreenProps<any, 'Welcome'>;

export const WelcomeScreen = ({ navigation }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <Layout>
      <LanguageHeader selectedLanguage={i18n.language} onChange={(lang) => i18n.changeLanguage(lang)} />

      <Distribution>
        <Logo />

        <Container>
          <Headline text={t('mctq.welcome.headline')} type="primary" />

          <SubHeadline text={t('mctq.welcome.subHeadline')} />

          <Text>{t('mctq.welcome.text')}</Text>
        </Container>

        <Container>
          <ProgressButton onPress={() => navigation.push('Gender')} label={t('mctq.navigation.start')} />
        </Container>
      </Distribution>

      <Footer />
    </Layout>
  );
};
