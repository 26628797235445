import React, { Component } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Svg, { Line, Path } from 'react-native-svg';
import ThemeService from '../services/ThemeService';

interface Props {
  onClose?: () => void;
  onBack?: () => void;
}

export const NavigationHeader = ({ onClose, onBack }: Props) => {
  return (
    <View style={styles.container}>
      {onBack && (
        <TouchableOpacity style={styles.button} onPress={onBack}>
          <Svg width="9" height="16" viewBox="0 0 9 16" fill="none">
            <Path d="M8 1L0.999999 8L8 15" stroke="white" strokeWidth="1.2" strokeMiterlimit="10" />
          </Svg>
        </TouchableOpacity>
      )}
      {!onBack && <View />}

      <TouchableOpacity style={styles.button} onPress={onClose}>
        <Svg width="15" height="13" viewBox="0 0 15 13" fill="none">
          <Line
            y1="-0.75"
            x2="17.6918"
            y2="-0.75"
            transform="matrix(0.734803 0.67828 -0.79017 0.612888 0 1)"
            stroke="white"
            strokeWidth="1.5"
          />
          <Line
            y1="-0.75"
            x2="17.6918"
            y2="-0.75"
            transform="matrix(-0.734803 0.67828 -0.79017 -0.612888 13 0)"
            stroke="white"
            strokeWidth="1.5"
          />
        </Svg>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    justifyContent: 'center',
    padding: 10,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
});
