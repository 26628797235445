import React from 'react';
import { StyleSheet, Image, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

interface Props {}

export const Logo = ({}: Props) => {
  return (
    <View style={styles.container}>
      <Svg width="220" height="53" viewBox="0 0 220 53" fill="none">
        <Path
          d="M11.55 1.44L26.39 39.52H26.53L41.16 1.44H51.94V51H45.01V9.56H44.87L28.42 51H24.01L7.63 9.56H7.49V51H0.77V1.44H11.55ZM101.462 39.52L106.992 43.3C102.606 49.2733 96.3058 52.26 88.0924 52.26C80.4858 52.26 74.2324 49.81 69.3324 44.91C64.4324 40.01 61.9824 33.7567 61.9824 26.15C61.9824 18.4967 64.4791 12.2667 69.4724 7.46C74.4658 2.60667 80.7658 0.179999 88.3724 0.179999C91.9191 0.179999 95.3258 0.856666 98.5924 2.21C101.859 3.56333 104.379 5.45333 106.152 7.88L100.692 12.08C99.4791 10.3533 97.7524 8.95333 95.5124 7.88C93.2724 6.80666 90.8924 6.27 88.3724 6.27C82.7724 6.27 78.2224 8.18333 74.7224 12.01C71.2691 15.8367 69.5424 20.55 69.5424 26.15C69.5424 31.8433 71.2458 36.6033 74.6524 40.43C78.0591 44.21 82.5624 46.1 88.1624 46.1C93.9958 46.1 98.4291 43.9067 101.462 39.52ZM131.513 7.53V51H124.583V7.53H108.693V1.44H147.403V7.53H131.513ZM204.747 45.19V51H175.627C168.021 51 161.814 48.69 157.007 44.07C152.247 39.4033 149.867 33.2433 149.867 25.59C149.867 18.17 152.294 12.08 157.147 7.32C162.047 2.56 168.231 0.179999 175.697 0.179999C183.071 0.179999 189.184 2.56 194.037 7.32C198.891 12.08 201.317 18.17 201.317 25.59C201.317 29.8833 200.384 33.71 198.517 37.07C196.697 40.43 194.224 43.09 191.097 45.05V45.19H204.747ZM157.357 25.59C157.357 31.1433 159.037 35.7633 162.397 39.45C165.757 43.09 170.167 44.91 175.627 44.91C180.994 44.91 185.381 43.0667 188.787 39.38C192.194 35.6933 193.897 31.0967 193.897 25.59C193.897 20.13 192.217 15.5567 188.857 11.87C185.544 8.13666 181.157 6.27 175.697 6.27C170.237 6.27 165.804 8.13666 162.397 11.87C159.037 15.6033 157.357 20.1767 157.357 25.59Z"
          fill="white"
        />
        <Path
          d="M218.456 43.58C219.39 44.4667 219.856 45.54 219.856 46.8C219.856 48.06 219.39 49.1567 218.456 50.09C217.57 50.9767 216.473 51.42 215.166 51.42C213.86 51.42 212.74 50.9767 211.806 50.09C210.92 49.1567 210.476 48.0833 210.476 46.87C210.476 45.61 210.92 44.5367 211.806 43.65C212.74 42.7167 213.86 42.25 215.166 42.25C216.473 42.25 217.57 42.6933 218.456 43.58Z"
          fill="#F28B2D"
        />
      </Svg>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '50%',
    resizeMode: 'center',
    height: 60,
  },
});
