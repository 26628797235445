import React, { Component } from 'react';
import { StyleProp, StyleSheet, Text as BaseText, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import ThemeService from '../services/ThemeService';

interface Props {
  children: any;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

export const Text = ({ children, containerStyle, textStyle }: Props) => {
  return (
    <View style={StyleSheet.compose(styles.container, containerStyle as any)}>
      <BaseText style={StyleSheet.compose(styles.text, textStyle as any)}>{children}</BaseText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginTop: 20,
  },
  text: {
    fontSize: 18,
    fontFamily: 'Nunito_400Regular',
    lineHeight: 24,
    color: ThemeService.getDefaultColor(),
  },
});
