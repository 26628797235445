import React from 'react';
import { useFonts } from 'expo-font';
import { Nunito_400Regular, Nunito_700Bold, Nunito_200ExtraLight } from '@expo-google-fonts/nunito';
import AppLoading from 'expo-app-loading';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useAssets } from 'expo-asset';
import { WelcomeScreen } from './src/screens/WelcomeScreen';
import { GenderScreen } from './src/screens/GenderScreen';
import { BodyDataScreen } from './src/screens/BodyDataScreen';
import { ResidenceScreen } from './src/screens/ResidenceScreen';
import { QuestionScreen } from './src/screens/QuestionScreen';
import { ResultScreen } from './src/screens/ResultScreen';
import { MctqConfigProvider } from './src/services/mctq/mctqConfigContext';
import './src/i18n/i18n';
import { IntroScreen } from './src/screens/IntroScreen';

export default function App() {
  const [fontsLoaded] = useFonts({
    Nunito_400Regular,
    Nunito_700Bold,
    Nunito_200ExtraLight,
  });
  const [assets] = useAssets([
    require('./assets/mctq-icons/inBed.png'),
    require('./assets/mctq-icons/chronotype.png'),
    require('./assets/mctq-icons/daylight.png'),
    require('./assets/mctq-icons/getUp.png'),
    require('./assets/mctq-icons/goToBed.png'),
    require('./assets/mctq-icons/sleep.png'),
    require('./assets/mctq-icons/tryToSleep.png'),
    require('./assets/mctq-icons/wakeup.png'),
    require('./assets/mctq-icons/chronotype/1.png'),
    require('./assets/mctq-icons/chronotype/2.png'),
    require('./assets/mctq-icons/chronotype/3.png'),
    require('./assets/mctq-icons/chronotype/4.png'),
    require('./assets/mctq-icons/chronotype/5.png'),
    require('./assets/mctq-icons/chronotype/6.png'),
    require('./assets/mctq-icons/chronotype/7.png'),
  ]);

  if (!fontsLoaded || !assets) {
    return <AppLoading />;
  }

  const Stack = createNativeStackNavigator();

  const defaultOptions: any = {
    headerShown: false,
    animation: 'none',
  };

  return (
    <SafeAreaProvider>
      <MctqConfigProvider>
        <NavigationContainer>
          <Stack.Navigator initialRouteName="Intro">
            <Stack.Screen name="Intro" options={defaultOptions} component={IntroScreen} />
            <Stack.Screen name="Welcome" options={defaultOptions} component={WelcomeScreen} />
            <Stack.Screen name="Gender" options={defaultOptions} component={GenderScreen} />
            <Stack.Screen name="BodyData" options={defaultOptions} component={BodyDataScreen} />
            <Stack.Screen name="Residence" options={defaultOptions} component={ResidenceScreen} />
            <Stack.Screen name="Question" options={defaultOptions} component={QuestionScreen} />
            <Stack.Screen name="Result" options={defaultOptions} component={ResultScreen} />
          </Stack.Navigator>
        </NavigationContainer>
      </MctqConfigProvider>
    </SafeAreaProvider>
  );
}
