import React from 'react';
import { Button, StyleSheet, TouchableOpacity, View } from 'react-native';
import ThemeService from '../services/ThemeService';
import { Headline } from './Headline';
import { SubHeadline } from './SubHeadline';
import { Text } from './Text';
import Svg, { Line } from 'react-native-svg';

interface Props {
  onClose: () => void;
  headline: string;
  subHeadline?: string;
  text: string;
}

export const Popup = ({ onClose, headline, subHeadline, text }: Props) => {
  return (
    <View style={styles.container}>
      <View style={styles.background} />

      <View style={styles.popover}>
        <View style={styles.handleContainer}>
          <View style={styles.handle} />
        </View>

        <Headline text={headline} type={'primary'} />
        {subHeadline && <Headline text={subHeadline} type={'light'} />}
        <Text containerStyle={styles.textContainer}>{text}</Text>

        <View style={styles.buttonContainer}>
          <TouchableOpacity onPress={onClose} style={styles.button}>
            <Svg width="17" height="17" viewBox="0 0 17 17" fill="none">
              <Line
                y1="-1"
                x2="20.4133"
                y2="-1"
                transform="matrix(0.734814 0.678269 -0.790179 0.612877 0 2.1543)"
                stroke={ThemeService.getPrimaryInvertedColor()}
                strokeWidth="2"
              />
              <Line
                y1="-1"
                x2="20.4133"
                y2="-1"
                transform="matrix(-0.734814 0.678269 -0.790179 -0.612877 15 1)"
                stroke={ThemeService.getPrimaryInvertedColor()}
                strokeWidth="2"
              />
            </Svg>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  background: {
    backgroundColor: ThemeService.getSecondaryColor(),
    opacity: 0.82,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  popover: {
    backgroundColor: ThemeService.getTileColor(),
    padding: 40,
    paddingTop: 14,
    position: 'absolute',
    bottom: 0,
    left: 0,
    borderRadius: 20,
    width: '100%',
  },
  handleContainer: {
    flex: 1,
    alignItems: 'center',
    marginBottom: 40,
  },
  handle: {
    height: 7,
    width: 72,
    borderRadius: 30,
    backgroundColor: ThemeService.getBackgroundColor(),
  },
  button: {
    backgroundColor: ThemeService.getPrimaryColor(),
    borderRadius: 45,
    width: 45,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 40,
  },
  textContainer: {
    marginTop: 20,
  },
});
