import React, { useState } from 'react';
import ChronotypeConfig from '../../interfaces/ChronotypeConfig';
import useSections from './useSections';
import QuestionsSection from '../../interfaces/QuestionsSection';
import QuestionsControl from '../../interfaces/QuestionsControl';

export const MctqConfigContext = React.createContext<{
  config: ChronotypeConfig | undefined;
  getConfigValue: (section: QuestionsSection, control: QuestionsControl) => any;
  setConfigValue: (section: QuestionsSection, control: QuestionsControl, value: any) => void;
}>({
  config: undefined,
  getConfigValue: () => null,
  setConfigValue: () => null,
});

export const MctqConfigProvider = ({ children }: { children: any }) => {
  const sections = useSections();

  const initialConfig: Partial<ChronotypeConfig> = {
    workDay_numberOfDays: 5, // TODO
    freeDay_numberOfDays: 2, // TODO
  };
  sections.forEach((s) => {
    s.questions.forEach((q) => {
      if (q.controls) {
        q.controls.forEach((control) => {
          const configParameterName = `${s.id}_${control.id}`;
          // @ts-ignore
          initialConfig[configParameterName] = control.defaultValue;
        });
      }
    });
  });

  const [config, setConfig] = useState<ChronotypeConfig>(initialConfig as ChronotypeConfig);

  const getConfigValue = (section: QuestionsSection, control: QuestionsControl) => {
    const configParameterName = `${section.id}_${control.id}` as keyof ChronotypeConfig;
    return config[configParameterName];
  };

  const setConfigValue = (section: QuestionsSection, control: QuestionsControl, value: any) => {
    const configParameterName = `${section.id}_${control.id}` as keyof ChronotypeConfig;

    setConfig({
      ...config,
      [configParameterName]: value,
    });

    console.log('NEW CONFIG', {
      ...config,
      [configParameterName]: value,
    });
  };

  return (
    <MctqConfigContext.Provider
      value={{
        config,
        getConfigValue,
        setConfigValue,
      }}
    >
      {children}
    </MctqConfigContext.Provider>
  );
};
