import React from 'react';
import { Dimensions, Image, StyleSheet, View } from 'react-native';

interface Props {
  chronotype: number;
}

const maxWidth = 400;
const windowWidth = Dimensions.get('window').width;
let iconWidth = windowWidth * 0.8;
if (iconWidth > maxWidth) {
  iconWidth = maxWidth;
}

export const ChronotypeIcon = ({ chronotype }: Props) => {
  const getIconSource = () => {
    if (chronotype === 1) return require('../../assets/mctq-icons/chronotype/1.png');
    if (chronotype === 2) return require('../../assets/mctq-icons/chronotype/2.png');
    if (chronotype === 3) return require('../../assets/mctq-icons/chronotype/3.png');
    if (chronotype === 4) return require('../../assets/mctq-icons/chronotype/4.png');
    if (chronotype === 5) return require('../../assets/mctq-icons/chronotype/5.png');
    if (chronotype === 6) return require('../../assets/mctq-icons/chronotype/6.png');
    if (chronotype === 7) return require('../../assets/mctq-icons/chronotype/7.png');
  };

  return (
    <View style={styles.container}>
      <Image source={getIconSource()} style={styles.image} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    marginBottom: 50,
  },
  image: {
    width: iconWidth,
    height: iconWidth / (300 / 160),
  },
});
