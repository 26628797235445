import Question from '../../interfaces/Question';
import QuestionsSection from '../../interfaces/QuestionsSection';
import useSections from './useSections';

export default function useQuestion(questionNumber: number) {
  const sections = useSections();

  const questionsMap: {
    section: QuestionsSection;
    question: Question;
    questionNumberInSection: number;
  }[] = [];

  sections.forEach((section) => {
    section.questions.forEach((question, questionKey) => {
      questionsMap.push({
        section,
        question,
        questionNumberInSection: questionKey + 1,
      });
    });
  });

  return questionsMap[questionNumber - 1];
}
