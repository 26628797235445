import React from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/Layout';
import { Headline } from '../components/Headline';
import { SubHeadline } from '../components/SubHeadline';
import { Text } from '../components/Text';
import { ProgressButton } from '../components/ProgressButton';
import { Container } from '../components/Container';
import { Footer } from '../components/Footer';
import { Distribution } from '../components/Distribution';
import { LanguageHeader } from '../components/LanguageHeader';

type Props = NativeStackScreenProps<any, 'Intro'>;

export const IntroScreen = ({ navigation, route }: Props) => {
  const { t, i18n } = useTranslation();
  const id = route.params?.id || 1;

  return (
    <Layout>
      <LanguageHeader selectedLanguage={i18n.language} onChange={(lang) => i18n.changeLanguage(lang)} />

      <Distribution>
        <Container>
          <Headline text={t(`mctq.info${id}.headline` as any)} type="primary" />
          <Headline text={t(`mctq.info${id}.subHeadline` as any)} />

          <Text
            textStyle={{
              fontFamily: 'Nunito_700Bold',
            }}
          >
            {t(`mctq.info${id}.textHeadline` as any)}
          </Text>

          <Text>{t(`mctq.info${id}.text` as any)}</Text>
        </Container>

        <Container>
          <ProgressButton
            onPress={() => (id === 1 ? navigation.push('Intro', { id: 2 }) : navigation.push('Question'))}
            label={id === 1 ? t('mctq.navigation.next') : t('mctq.navigation.start')}
          />
        </Container>
      </Distribution>

      <Footer />
    </Layout>
  );
};
