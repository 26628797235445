import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './de.json';
import en from './en.json';
import kr from './kr.json';

export enum Languages {
  english = 'en',
  korean = 'kr',
  german = 'de',
}

i18n.use(initReactI18next).init({
  fallbackLng: 'de',
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  defaultNS: 'translations',
  resources: {
    de,
    en,
    kr,
  },
});

export default i18n;
