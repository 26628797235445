import React, { useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  LayoutChangeEvent,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import ThemeService from '../services/ThemeService';
import Helper from '../services/Helper';

export enum LabelInterval {
  'everyValue',
  'everyTenValues',
}

interface Props {
  value: number;
  minValue: number;
  maxValue: number;
  labelInterval: LabelInterval;
  label: string;
  unit: string;
  onChange?: (val: number) => void;
}

export const Slider = ({ value, minValue, maxValue, labelInterval, label, unit, onChange }: Props) => {
  const scrollContainerRef = useRef<ScrollView>(null);
  const [padding, setPadding] = useState<number>(0);
  const [isControlled, setIsControlled] = useState<boolean>(false);
  const [displayedValue, setDisplayedValue] = useState<number>(0);

  const [currentScrollPosition, setCurrentScrollPosition] = useState<number>(0);
  const debouncedScrollPosition = Helper.useDebounce<number>(currentScrollPosition, 100);

  const indicatorWidth = labelInterval === LabelInterval.everyTenValues ? 10 : 20;

  useEffect(() => {
    let val = Math.round(minValue + debouncedScrollPosition / indicatorWidth);
    if (val < minValue) val = minValue;
    if (val > maxValue) val = maxValue;
    if (onChange) onChange(val);
    setIsControlled(false);
    setDisplayedValue(val);
    if (Platform.OS === 'web') {
      setTimeout(() => {
        // scrollContainerRef.current?.scrollTo({ x: minValue + val * indicatorWidth, animated: true });
      }, 100);
    }
  }, [debouncedScrollPosition]);

  useEffect(() => {
    if (!isControlled) setIsControlled(true);
    let val = Math.round(minValue + currentScrollPosition / indicatorWidth);
    if (val < minValue) val = minValue;
    if (val > maxValue) val = maxValue;
    setDisplayedValue(val);
  }, [currentScrollPosition]);

  useEffect(() => {
    setDisplayedValue(value);
    scrollContainerRef.current?.scrollTo({ x: (value - minValue) * indicatorWidth, animated: false });
  }, [value]);

  const values = [];
  for (let i = minValue; i <= maxValue; i++) {
    values.push(i);
  }

  const onLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setPadding(width / 2 - indicatorWidth / 2);
  };

  const indicatorContainerStyle = StyleSheet.compose(
    styles.indicatorContainer,
    StyleSheet.create({ width: { width: indicatorWidth } }).width,
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.label}>{label}</Text>
        <View style={styles.unitContainer}>
          <Text style={styles.unitLabel}>{unit}</Text>
        </View>
      </View>

      <View style={styles.valueContainer}>
        <Text style={styles.value}>{displayedValue}</Text>
      </View>

      <ScrollView
        onLayout={onLayout}
        ref={scrollContainerRef}
        style={styles.scrollContainer}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        horizontal={true}
        snapToInterval={indicatorWidth}
        scrollEventThrottle={16}
        onScroll={(event) => setCurrentScrollPosition(event.nativeEvent.contentOffset.x)}
        onMomentumScrollEnd={(event) => setCurrentScrollPosition(event.nativeEvent.contentOffset.x)}
      >
        <View style={StyleSheet.create({ spacer: { width: padding } }).spacer} />
        {values.map((val, key) => {
          let indicatorStyle = styles.indicator as any;

          if (labelInterval === LabelInterval.everyTenValues) {
            if (key % 10 === 0) {
              indicatorStyle = StyleSheet.compose(styles.indicator, styles.indicator10);
            } else if (key % 5 === 0) {
              indicatorStyle = StyleSheet.compose(styles.indicator, styles.indicator5);
            }
          } else if (labelInterval === LabelInterval.everyValue) {
            indicatorStyle = StyleSheet.compose(styles.indicator, styles.indicator10);
          }

          return (
            <View style={indicatorContainerStyle} key={key}>
              <View style={styles.indicatorInnerContainer}>
                <View style={indicatorStyle} />
              </View>
              {labelInterval === LabelInterval.everyValue && <Text style={styles.indicatorLabel}>{val}</Text>}
              {labelInterval === LabelInterval.everyTenValues && key % 10 === 0 && (
                <Text style={styles.indicatorLabel}>{val}</Text>
              )}
            </View>
          );
        })}
        <View style={StyleSheet.create({ spacer: { width: padding } }).spacer} />
      </ScrollView>

      <View style={styles.marker} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  scrollContainer: {
    width: '100%',
    height: 60,
    paddingHorizontal: 0,
    paddingTop: 22,
  },
  indicatorContainer: {
    width: 20,
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'visible',
  },
  indicatorInnerContainer: {
    height: 15,
    justifyContent: 'flex-end',
  },
  indicator: {
    backgroundColor: ThemeService.getDefaultColor(),
    height: 10,
    width: 1,
    marginBottom: 8,
    opacity: 0.5,
  },
  indicator10: {
    opacity: 1,
    height: 15,
  },
  indicator5: {
    opacity: 0.8,
    height: 12,
  },
  indicatorLabel: {
    fontSize: 8,
    color: ThemeService.getDefaultColor(),
    fontFamily: 'Nunito_700Bold',
    width: 20,
    textAlign: 'center',
  },
  marker: {
    backgroundColor: ThemeService.getPrimaryColor(),
    width: 3,
    height: 26,
    position: 'absolute',
    bottom: 31,
    left: '50%',
    marginLeft: -1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingBottom: 10,
    alignItems: 'center',
  },
  label: {
    fontSize: 18,
    fontFamily: 'Nunito_400Regular',
    color: ThemeService.getDefaultColor(),
  },
  valueContainer: {
    position: 'absolute',
    top: 3,
    left: 0,
    right: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  value: {
    fontSize: 24,
    fontFamily: 'Nunito_700Bold',
    color: ThemeService.getPrimaryColor(),
  },
  unitContainer: {
    backgroundColor: ThemeService.getTileColor(),
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 10,
  },
  unitLabel: {
    color: ThemeService.getDefaultColor(),
    fontSize: 10,
    fontFamily: 'Nunito_700Bold',
  },
});
