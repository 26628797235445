import React from 'react';
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import ThemeService from '../services/ThemeService';

interface Props {
  onPress: () => void;
  children: string;
  type?: 'primary' | 'secondary';
}

export const Button = ({ onPress, children, type }: Props) => {
  let buttonStyle = styles.primary;
  let labelStyle = styles.primaryLabel;

  if (type === 'secondary') {
    buttonStyle = styles.secondary;
    labelStyle = styles.secondaryLabel;
  }

  return (
    <TouchableOpacity onPress={onPress} style={StyleSheet.compose(styles.button, buttonStyle)}>
      <Text style={StyleSheet.compose(styles.label, labelStyle)}>{children}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderRadius: 52,
    paddingVertical: 11,
    paddingHorizontal: 20,
    backgroundColor: ThemeService.getPrimaryColor(),
  },
  label: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Nunito_700Bold',
    color: ThemeService.getPrimaryInvertedColor(),
  },
  primary: {
    backgroundColor: ThemeService.getPrimaryColor(),
  },
  secondary: {
    backgroundColor: ThemeService.getSecondaryColor(),
  },
  primaryLabel: {
    color: ThemeService.getPrimaryInvertedColor(),
  },
  secondaryLabel: {
    color: ThemeService.getSecondaryInvertedColor(),
  },
});
