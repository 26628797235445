import React from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { StatusBar } from 'expo-status-bar';
import ThemeService from '../services/ThemeService';

interface Props {
  children: any;
}

export const Layout = ({ children }: Props) => {
  return (
    <SafeAreaView style={styles.container}>
      {children}
      <StatusBar style="light" hidden />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: ThemeService.getBackgroundColor(),
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    padding: 25,
  },
});
