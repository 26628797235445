import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';

interface Props {
  children: any;
}

export const Container = ({ children }: Props) => {
  return <View style={styles.container}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
});
