import { useContext, useEffect, useState } from 'react';
import useQuestion from './useQuestion';
import { MctqConfigContext } from './mctqConfigContext';

export default function useQuestionConfig(questionNumber: number) {
  const { section, question } = useQuestion(questionNumber);

  const { setConfigValue, getConfigValue } = useContext(MctqConfigContext);

  const timeControl = question.controls?.find((control) => control.type === undefined);
  const toggleControl = question.controls?.find((control) => control.type === 'toggle');

  const [hours, setHours] = useState<number>(timeControl ? getConfigValue(section, timeControl)[0] : 0);
  const [minutes, setMinutes] = useState<number>(timeControl ? getConfigValue(section, timeControl)[1] : 0);
  const [toggleState, setToggleState] = useState<boolean>(
    toggleControl ? getConfigValue(section, toggleControl) : false,
  );

  useEffect(() => {
    const control = question.controls?.find((c) => c.type === undefined);
    if (control) {
      setConfigValue(section, control, [hours, minutes]);
    }
  }, [hours, minutes]);

  useEffect(() => {
    const control = question.controls?.find((c) => c.type === 'toggle');
    if (control) {
      setConfigValue(section, control, toggleState);
    }
  }, [toggleState]);

  return { hours, minutes, toggleState, setHours, setMinutes, setToggleState };
}
