import React from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/Layout';
import { Headline } from '../components/Headline';
import { ProgressButton } from '../components/ProgressButton';
import { Container } from '../components/Container';
import { Distribution } from '../components/Distribution';
import { NavigationHeader } from '../components/NavigationHeader';
import { Footer } from '../components/Footer';

type Props = NativeStackScreenProps<any, 'Welcome'>;

export const ResidenceScreen = ({ navigation }: Props) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <NavigationHeader onClose={() => navigation.popToTop()} onBack={() => navigation.goBack()} />

      <Distribution>
        <Container>
          <Headline text={t('mctq.residence.headline')} />
        </Container>

        <Container>
          <ProgressButton
            onPress={() => navigation.push('Question')}
            label={t('mctq.navigation.next')}
            showSkip
            onPressSkip={() => navigation.push('Question')}
          />
        </Container>
      </Distribution>

      <Footer />
    </Layout>
  );
};
