import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import ThemeService from '../services/ThemeService';
import { Headline } from './Headline';
import { SubHeadline } from './SubHeadline';
import { Text } from './Text';
import Svg, { Line } from 'react-native-svg';
import { Button } from './Button';

interface Props {
  onCancel?: () => void;
  onConfirm?: () => void;
  headline: string;
  text: string;
  cancelLabel?: string;
  confirmLabel?: string;
}

export const Popover = ({ headline, text, onConfirm, onCancel, cancelLabel, confirmLabel }: Props) => {
  return (
    <View style={styles.container}>
      <View style={styles.background} />

      <View style={styles.popover}>
        <TouchableOpacity onPress={onCancel} style={styles.closeButton}>
          <Svg width="17" height="17" viewBox="0 0 17 17" fill="none">
            <Line
              y1="-1"
              x2="20.4133"
              y2="-1"
              transform="matrix(0.734814 0.678269 -0.790179 0.612877 0 2.1543)"
              stroke={ThemeService.getPrimaryInvertedColor()}
              strokeWidth="2"
            />
            <Line
              y1="-1"
              x2="20.4133"
              y2="-1"
              transform="matrix(-0.734814 0.678269 -0.790179 -0.612877 15 1)"
              stroke={ThemeService.getPrimaryInvertedColor()}
              strokeWidth="2"
            />
          </Svg>
        </TouchableOpacity>

        <Headline text={headline} type={'primary'} />
        <Text containerStyle={styles.textContainer}>{text}</Text>

        <View style={styles.buttonContainer}>
          {cancelLabel && onCancel && (
            <Button onPress={onCancel} type={'secondary'}>
              {cancelLabel}
            </Button>
          )}
          {confirmLabel && onConfirm && <Button onPress={onConfirm}>{confirmLabel}</Button>}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  background: {
    backgroundColor: ThemeService.getSecondaryColor(),
    opacity: 0.82,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  popover: {
    backgroundColor: ThemeService.getTileColor(),
    margin: 20,
    padding: 20,
    borderRadius: 20,
    paddingTop: 35,
    maxWidth: 500,
  },
  buttonContainer: {
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    marginTop: 40,
  },
  textContainer: {
    marginTop: 20,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 20,
  },
});
