import React, { useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import ThemeService from '../services/ThemeService';
import { Languages } from '../i18n/i18n';

interface Props {
  selectedLanguage: string;
  onChange: (language: Languages) => void;
}

export const LanguageHeader = ({ selectedLanguage, onChange }: Props) => {
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const languages = [
    {
      id: Languages.english,
      label: 'English',
      icon: require('../../assets/languages/eng.png'),
    },
    {
      id: Languages.korean,
      label: 'Korean',
      icon: require('../../assets/languages/kor.png'),
    },
    {
      id: Languages.german,
      label: 'German',
      icon: require('../../assets/languages/deu.png'),
    },
  ];

  const language = languages.find((lang) => lang.id === selectedLanguage) || languages[0];

  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity activeOpacity={1} style={styles.button} onPress={() => setDropdownVisible(!dropdownVisible)}>
          <Image source={language.icon} style={styles.icon} />
          <Text style={styles.label}>{language.label}</Text>
          <Svg width="16" height="9" viewBox="0 0 16 9" fill="none" style={styles.arrow}>
            <Path d="M1 1.0097L8.00485 8.00485L15 1" stroke="white" strokeWidth="1.2" strokeMiterlimit="10" />
          </Svg>
        </TouchableOpacity>

        {dropdownVisible && (
          <View style={styles.dropdownContainer}>
            {languages
              .filter((lang) => lang.id !== language.id)
              .map((lang) => (
                <TouchableOpacity
                  key={lang.id}
                  style={styles.dropdownButton}
                  onPress={() => {
                    onChange(lang.id);
                    setDropdownVisible(false);
                  }}
                >
                  <Image source={lang.icon} style={styles.icon} />
                  <Text style={styles.label}>{lang.label}</Text>
                </TouchableOpacity>
              ))}
          </View>
        )}
      </View>
      <View />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 50,
    right: 50,
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: ThemeService.getTileColor(),
    borderRadius: 20,
    paddingHorizontal: 15,
    paddingVertical: 6,
    width: 180,
  },
  dropdownContainer: {
    backgroundColor: ThemeService.getTileColor(),
    borderRadius: 20,
    marginTop: 10,
  },
  dropdownButton: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 20,
    paddingHorizontal: 15,
    paddingVertical: 6,
    width: 180,
    marginVertical: 5,
  },
  label: {
    fontSize: 12,
    fontFamily: 'Nunito_400Regular',
    lineHeight: 24,
    color: ThemeService.getDefaultColor(),
    marginHorizontal: 10,
  },
  icon: {
    width: 20,
    height: 20,
    borderRadius: 20,
  },
  arrow: {
    position: 'absolute',
    top: 14,
    right: 15,
  },
});
