import React from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import ThemeService from '../services/ThemeService';

interface Props {
  text: string;
  type?: 'primary' | 'light' | 'small';
  containerStyle?: ViewStyle;
  textStyle?: TextStyle;
}

export const Headline = ({ text, type, containerStyle, textStyle }: Props) => {
  return (
    <View style={StyleSheet.compose(styles.container, containerStyle as any)}>
      <Text
        style={StyleSheet.compose(type ? StyleSheet.compose(styles.text, styles[type]) : styles.text, textStyle as any)}
      >
        {text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  text: {
    fontSize: 30,
    lineHeight: 35,
    fontFamily: 'Nunito_400Regular',
    color: ThemeService.getDefaultColor(),
  },
  primary: {
    color: ThemeService.getPrimaryColor(),
  },
  light: {
    fontFamily: 'Nunito_200ExtraLight',
    color: ThemeService.getDefaultColor(),
  },
  small: {
    fontFamily: 'Nunito_200ExtraLight',
    color: ThemeService.getDefaultColor(),
    fontSize: 12,
    lineHeight: 24,
  },
});
