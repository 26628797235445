import React from 'react';
import { Dimensions, Image, StyleSheet, View } from 'react-native';

interface Props {
  id: IconIds;
}

export enum IconIds {
  inBed = 'inBed',
  chronotype = 'chronotype',
  daylight = 'daylight',
  getUp = 'getUp',
  goToBed = 'goToBed',
  sleep = 'sleep',
  tryToSleep = 'tryToSleep',
  wakeup = 'wakeup',
}

const maxWidth = 500;
const windowWidth = Dimensions.get('window').width;
let iconWidth = windowWidth * 0.8;
if (iconWidth > maxWidth) {
  iconWidth = maxWidth;
}

export const Icon = ({ id }: Props) => {
  const getIconSource = () => {
    if (id === IconIds.inBed) return require('../../assets/mctq-icons/inBed.png');
    if (id === IconIds.chronotype) return require('../../assets/mctq-icons/chronotype/1.svg');
    if (id === IconIds.daylight) return require('../../assets/mctq-icons/daylight.png');
    if (id === IconIds.getUp) return require('../../assets/mctq-icons/getUp.png');
    if (id === IconIds.goToBed) return require('../../assets/mctq-icons/goToBed.png');
    if (id === IconIds.sleep) return require('../../assets/mctq-icons/sleep.png');
    if (id === IconIds.tryToSleep) return require('../../assets/mctq-icons/tryToSleep.png');
    if (id === IconIds.wakeup) return require('../../assets/mctq-icons/wakeup.png');
  };

  return (
    <View style={styles.container}>
      <Image source={getIconSource()} style={styles.image} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
  },
  image: {
    width: iconWidth,
    height: iconWidth / (300 / 160),
  },
});
