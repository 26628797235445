import useSections from './useSections';

let numberOfQuestions = 0;

export default function useNumberOfQuestions(): number {
  const sections = useSections();

  if (numberOfQuestions === 0) {
    console.log('CALC');
    sections.forEach((section) => {
      section.questions.forEach(() => {
        numberOfQuestions++;
      });
    });
  }

  return numberOfQuestions;
}
