import React, { useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Layout } from '../components/Layout';
import { Headline } from '../components/Headline';
import { ProgressButton } from '../components/ProgressButton';
import { Container } from '../components/Container';
import { Footer } from '../components/Footer';
import { Distribution } from '../components/Distribution';
import { NavigationHeader } from '../components/NavigationHeader';
import { PreHeadline } from '../components/PreHeadline';
import { TimePicker } from '../components/TimePicker';
import { Icon } from '../components/Icon';
import { Popover } from '../components/Popover';
import { Popup } from '../components/Popup';
import useQuestion from '../services/mctq/useQuestion';
import useNumberOfQuestions from '../services/mctq/useNumberOfQuestions';
import useQuestionConfig from '../services/mctq/useQuestionConfig';

type Props = NativeStackScreenProps<any, 'Welcome'>;

export const QuestionScreen = ({ navigation, route }: Props) => {
  const { t } = useTranslation();

  const questionNumber = route.params?.questionNumber || 1;

  const numberOfQuestions = useNumberOfQuestions();

  const { section, question, questionNumberInSection } = useQuestion(questionNumber);

  const { hours, minutes, toggleState, setHours, setMinutes, setToggleState } = useQuestionConfig(questionNumber);

  // Modal states
  const [infoIsVisible, setInfoIsVisible] = useState<boolean>(questionNumber === 1);
  const [halfTimeInfoIsVisible, setHalfTimeInfoIsVisible] = useState<boolean>(
    questionNumber > 1 && questionNumberInSection === 1,
  );
  const [cancelConfirmIsVisible, setCancelConfirmIsVisible] = useState<boolean>(false);

  return (
    <Layout>
      <NavigationHeader onClose={() => setCancelConfirmIsVisible(true)} onBack={() => navigation.goBack()} />

      <Distribution>
        <Container>{question.icon && <Icon id={question.icon} />}</Container>

        <Container>
          <PreHeadline text={section.title} />
          <Headline text={question.text} />
          {question.hint && <Headline text={question.hint} type="small" />}
        </Container>

        {question.controls?.map((control) => {
          if (control.type === 'toggle') {
            // TODO
            return (
              <Container key={control.id}>
                <View />
              </Container>
            );
          }

          return (
            <Container key={control.id}>
              <TimePicker
                hours={hours}
                minutes={minutes}
                onChangeHours={(val) => setHours(val)}
                onChangeMinutes={(val) => setMinutes(val)}
              />
            </Container>
          );
        })}

        <Container>
          <ProgressButton
            progress={(questionNumber * 100) / numberOfQuestions}
            onPress={() => {
              if (questionNumber === numberOfQuestions) {
                navigation.push('Result');
              } else {
                navigation.push('Question', {
                  questionNumber: questionNumber + 1,
                });
              }
            }}
            label={t('mctq.navigation.next')}
          />
        </Container>
      </Distribution>

      <Footer />

      {infoIsVisible && (
        <Popup
          text={t('mctq.questions.info1.text')}
          headline={t('mctq.questions.info1.headline')}
          onClose={() => setInfoIsVisible(false)}
        />
      )}

      {halfTimeInfoIsVisible && (
        <Popup
          text={t('mctq.questions.info2.text')}
          headline={t('mctq.questions.info2.headline')}
          subHeadline={t('mctq.questions.info2.subHeadline')}
          onClose={() => setHalfTimeInfoIsVisible(false)}
        />
      )}

      {cancelConfirmIsVisible && (
        <Popover
          headline={t('mctq.closeDialog.headline')}
          text={t('mctq.closeDialog.text')}
          cancelLabel={t('mctq.closeDialog.cancelLabel')}
          confirmLabel={t('mctq.closeDialog.confirmLabel')}
          onCancel={() => setCancelConfirmIsVisible(false)}
          onConfirm={() => navigation.popToTop()}
        />
      )}
    </Layout>
  );
};
