import React from 'react';
import { Button, StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import ThemeService from '../services/ThemeService';
import Svg, { Circle, Line, Path } from 'react-native-svg';

export enum Gender {
  male,
  female,
  other,
}

interface Props {
  onChange: (gender: Gender) => void;
  selected?: Gender;
}

export const GenderSelect = ({ onChange, selected }: Props) => {
  const options = [
    {
      label: 'Männlich',
      value: Gender.male,
    },
    {
      label: 'Weiblich',
      value: Gender.female,
    },
    {
      label: 'Divers',
      value: Gender.other,
    },
  ];

  const getIcon = (type: Gender, color: string) => {
    const bgColor = ThemeService.getTileColor();

    if (type === Gender.male) {
      return (
        <Svg width="32" height="30" viewBox="0 0 32 30" fill="none">
          <Circle cx="10" cy="20" r="9.25" fill={bgColor} stroke={color} strokeWidth="1.5" />
          <Path
            d="M17.5016 13.078C17.1921 13.3532 17.1643 13.8273 17.4395 14.1368C17.7148 14.4463 18.1888 14.4741 18.4984 14.1989L17.5016 13.078ZM28.4634 5.04379C28.4877 4.63028 28.1721 4.27542 27.7586 4.25117L21.0202 3.85605C20.6067 3.83181 20.2518 4.14736 20.2276 4.56087C20.2033 4.97437 20.5189 5.32924 20.9324 5.35348L26.9221 5.7047L26.5709 11.6944C26.5466 12.1079 26.8622 12.4628 27.2757 12.487C27.6892 12.5113 28.0441 12.1957 28.0683 11.7822L28.4634 5.04379ZM18.4984 14.1989L28.2131 5.56035L27.2163 4.43942L17.5016 13.078L18.4984 14.1989Z"
            fill={color}
          />
        </Svg>
      );
    }

    if (type === Gender.female) {
      return (
        <Svg width="20" height="32" viewBox="0 0 20 32" fill="none">
          <Circle cx="10" cy="10" r="9.25" fill={bgColor} stroke={color} strokeWidth="1.5" />
          <Line x1="10.75" y1="19" x2="10.75" y2="32" stroke={color} strokeWidth="1.5" />
          <Line x1="14.995" y1="25.8066" x2="5.99519" y2="25.75" stroke={color} strokeWidth="1.5" />
        </Svg>
      );
    }

    if (type === Gender.other) {
      return (
        <Svg width="44" height="42" viewBox="0 0 44 42" fill="none">
          <Circle cx="22" cy="20" r="9.25" fill={bgColor} stroke={color} strokeWidth="1.5" />
          <Line x1="22.75" y1="29" x2="22.75" y2="42" stroke={color} strokeWidth="1.5" />
          <Line x1="26.995" y1="35.8066" x2="17.9952" y2="35.75" stroke={color} strokeWidth="1.5" />
          <Line x1="13.8717" y1="7.52828" x2="7.53231" y2="13.9167" stroke={color} strokeWidth="1.5" />
          <Path
            d="M29.5016 14.078C29.1921 14.3532 29.1643 14.8273 29.4395 15.1368C29.7148 15.4463 30.1888 15.4741 30.4984 15.1989L29.5016 14.078ZM40.4634 6.04379C40.4877 5.63028 40.1721 5.27542 39.7586 5.25117L33.0202 4.85605C32.6067 4.83181 32.2518 5.14736 32.2276 5.56087C32.2033 5.97437 32.5189 6.32924 32.9324 6.35348L38.9221 6.7047L38.5709 12.6944C38.5466 13.1079 38.8622 13.4628 39.2757 13.487C39.6892 13.5113 40.0441 13.1957 40.0683 12.7822L40.4634 6.04379ZM30.4984 15.1989L40.2131 6.56035L39.2163 5.43942L29.5016 14.078L30.4984 15.1989Z"
            fill={color}
          />
          <Path
            d="M13.8268 14.5735C14.1247 14.8613 14.5995 14.8532 14.8873 14.5554C15.1752 14.2575 15.1671 13.7827 14.8692 13.4949L13.8268 14.5735ZM4.98725 4.25017C4.57309 4.25724 4.24309 4.59871 4.25016 5.01286L4.36539 11.7619C4.37246 12.176 4.71393 12.506 5.12808 12.499C5.54224 12.4919 5.87224 12.1504 5.86517 11.7363L5.76274 5.73714L11.7619 5.63472C12.176 5.62765 12.506 5.28618 12.499 4.87202C12.4919 4.45787 12.1504 4.12786 11.7363 4.13494L4.98725 4.25017ZM14.8692 13.4949L5.52125 4.46075L4.47885 5.53936L13.8268 14.5735L14.8692 13.4949Z"
            fill={color}
          />
        </Svg>
      );
    }

    return <View />;
  };

  return (
    <View style={styles.container}>
      {options.map((option, key) => {
        const color = option.value === selected ? ThemeService.getPrimaryColor() : ThemeService.getDefaultColor();

        return (
          <TouchableOpacity activeOpacity={0.9} style={styles.option} key={key} onPress={() => onChange(option.value)}>
            {getIcon(option.value, color)}
            <Text style={StyleSheet.compose(styles.text, { color })}>{option.label}</Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  option: {
    backgroundColor: ThemeService.getTileColor(),
    padding: 20,
    width: 130,
    height: 110,
    borderRadius: 20,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  text: {
    color: ThemeService.getDefaultColor(),
  },
});
