import React from 'react';
import { View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/Layout';
import { Headline } from '../components/Headline';
import { Distribution } from '../components/Distribution';
import { NavigationHeader } from '../components/NavigationHeader';
import { Icon, IconIds } from '../components/Icon';
import { PreHeadline } from '../components/PreHeadline';
import useResult from '../services/mctq/useResult';
import { Footer } from '../components/Footer';
import { ProgressButton } from '../components/ProgressButton';
import { Container } from '../components/Container';
import { Text } from '../components/Text';
import { ChronotypeIcon } from '../components/ChronotypeIcon';

type Props = NativeStackScreenProps<any, 'Welcome'>;

export const ResultScreen = ({ navigation }: Props) => {
  const { t } = useTranslation();

  const chronotype = useResult();

  const getLabel = () => {
    if (chronotype === 1) return t('mctq.result.chronotype1.name');
    if (chronotype === 2) return t('mctq.result.chronotype2.name');
    if (chronotype === 3) return t('mctq.result.chronotype3.name');
    if (chronotype === 4) return t('mctq.result.chronotype4.name');
    if (chronotype === 5) return t('mctq.result.chronotype5.name');
    if (chronotype === 6) return t('mctq.result.chronotype6.name');
    return t('mctq.result.chronotype7.name');
  };

  const getText = () => {
    if (chronotype === 1) return t('mctq.result.chronotype1.text');
    if (chronotype === 2) return t('mctq.result.chronotype2.text');
    if (chronotype === 3) return t('mctq.result.chronotype3.text');
    if (chronotype === 4) return t('mctq.result.chronotype4.text');
    if (chronotype === 5) return t('mctq.result.chronotype5.text');
    if (chronotype === 6) return t('mctq.result.chronotype6.text');
    return t('mctq.result.chronotype7.text');
  };

  const getColor = () => {
    if (chronotype === 1) return '#8823C9';
    if (chronotype === 2) return '#1F1FE8';
    if (chronotype === 3) return '#81F3FA';
    if (chronotype === 4) return '#78E24B';
    if (chronotype === 5) return '#EAEA42';
    if (chronotype === 6) return '#FF9300';
    return '#FF1F3A';
  };

  return (
    <Layout>
      <NavigationHeader onClose={() => navigation.popToTop()} />

      <Distribution>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            flex: 1,
          }}
        >
          <ChronotypeIcon chronotype={chronotype} />
          <PreHeadline text={t('mctq.result.headline')} />
          <Headline
            text={getLabel()}
            type="primary"
            containerStyle={{
              marginTop: 15,
            }}
            textStyle={{
              color: getColor(),
            }}
          />
          <Text>{getText()}</Text>
        </View>

        <Container>
          <ProgressButton onPress={() => navigation.push('Intro')} label={t('mctq.navigation.done')} />
        </Container>
      </Distribution>

      <Footer />
    </Layout>
  );
};
