import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';

interface Props {
  children: any;
}

export const Distribution = ({ children }: Props) => {
  return <View style={styles.container}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'space-between',
    flex: 1,
    paddingVertical: 40,
    maxHeight: 800,
    maxWidth: 600,
  },
});
