import { useTranslation } from 'react-i18next';
import Question from '../../interfaces/Question';
import { IconIds } from '../../components/Icon';

export default function useQuestions() {
  const { t } = useTranslation();

  const questions: Question[] = [
    {
      icon: IconIds.goToBed,
      text: t('mctq.questions.goToBed.question'),
      controls: [
        {
          id: 'goToBedTime',
          defaultValue: [22, 0],
        },
      ],
    },
    {
      icon: IconIds.inBed,
      text: t('mctq.questions.decisionToSleep.lead'),
    },
    {
      icon: IconIds.inBed,
      text: t('mctq.questions.decisionToSleep.question'),
      controls: [
        {
          id: 'decisionToSleepTime',
          defaultValue: [22, 0],
        },
      ],
    },
    {
      icon: IconIds.sleep,
      text: t('mctq.questions.timeToFallAsleep.question'),
      controls: [
        {
          id: 'timeToFallAsleep',
          defaultValue: [0, 10],
        },
      ],
    },
    {
      icon: IconIds.wakeup,
      text: t('mctq.questions.wakeUp.question'),
      controls: [
        {
          id: 'wakeUpTime',
          defaultValue: [7, 0],
        },
        {
          id: 'withAlarmClock',
          type: 'toggle',
          defaultValue: false,
        },
      ],
    },
    {
      icon: IconIds.getUp,
      text: t('mctq.questions.timeToGetUp.question'),
      controls: [
        {
          id: 'timeToGetUp',
          defaultValue: [0, 10],
        },
      ],
    },
    {
      icon: IconIds.daylight,
      text: t('mctq.questions.daylight.question'),
      hint: t('mctq.questions.daylight.info'),
      controls: [
        {
          id: 'daylightTime',
          defaultValue: [6, 0],
        },
      ],
    },
  ];

  return questions;
}
