export default class ThemeService {
  public static getBackgroundColor() {
    return '#1B2834';
  }

  public static getTileColor() {
    return '#233443';
  }

  public static getDefaultColor() {
    return '#ffffff';
  }

  public static getPrimaryColor() {
    return '#F28B2D';
  }

  public static getPrimaryInvertedColor() {
    return '#ffffff';
  }

  public static getSecondaryColor() {
    return '#1B2834';
  }

  public static getSecondaryInvertedColor() {
    return '#ffffff';
  }

  public static getBackgroundColorAsRgba(opacity = 1) {
    const hex = ThemeService.getBackgroundColor();
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}
