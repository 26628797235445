import { useContext } from 'react';
import Helper from '../Helper';
import { MctqConfigContext } from './mctqConfigContext';

export default function useResult(): number {
  const { config } = useContext(MctqConfigContext);
  if (!config) return 0;

  console.log('CONFIG', config);

  // Work days
  const workDay_sleepOnset = Helper.addMinutesToTime(
    config.workDay_decisionToSleepTime,
    Helper.timeToMinutes(config.workDay_timeToFallAsleep),
  );

  /*
  const workDay_outOfBed = Helper.addMinutesToTime(
    config.workDay_wakeUpTime,
    Helper.timeToMinutes(config.workDay_timeToGetUp),
  );
   */

  const workDay_sleepDuration = Helper.getDurationInMinutes(workDay_sleepOnset, config.workDay_wakeUpTime);
  // const workDay_totalBedTime = Helper.getDurationInMinutes(config.workDay_goToBedTime, workDay_outOfBed);
  // const workDay_midSleep = Helper.addMinutesToTime(workDay_sleepOnset, workDay_sleepDuration / 2);

  // Free days
  const freeDay_sleepOnset = Helper.addMinutesToTime(
    config.freeDay_decisionToSleepTime,
    Helper.timeToMinutes(config.freeDay_timeToFallAsleep),
  );

  /*
  const freeDay_outOfBed = Helper.addMinutesToTime(
    config.freeDay_wakeUpTime,
    Helper.timeToMinutes(config.freeDay_timeToGetUp),
  );
   */

  const freeDay_sleepDuration = Helper.getDurationInMinutes(freeDay_sleepOnset, config.freeDay_wakeUpTime);
  // const freeDay_totalBedTime = Helper.getDurationInMinutes(config.freeDay_goToBedTime, freeDay_outOfBed);
  const freeDay_midSleep = Helper.addMinutesToTime(freeDay_sleepOnset, freeDay_sleepDuration / 2);

  // Combination of work days and free days
  const sleepDuration =
    (workDay_sleepDuration * config.workDay_numberOfDays + freeDay_sleepDuration * config.freeDay_numberOfDays) / 7;
  const correctedMidSleep = Helper.subtractMinutesFromTime(
    freeDay_midSleep,
    (freeDay_sleepDuration - sleepDuration) / 2,
  );

  /*
  let sleepLoss;
  if (sleepDuration > workDay_sleepDuration) {
    sleepLoss = (sleepDuration - workDay_sleepDuration) * config.workDay_numberOfDays;
  } else {
    sleepLoss = (sleepDuration - freeDay_sleepDuration) * config.freeDay_numberOfDays;
  }
  */

  let midSleep;
  if (freeDay_sleepDuration <= workDay_sleepDuration) {
    midSleep = freeDay_midSleep;
  } else {
    midSleep = correctedMidSleep;
  }

  console.log('RESULTS:', {
    workDay_sleepOnset,
    workDay_sleepDuration,
    freeDay_sleepOnset,
    freeDay_sleepDuration,
    freeDay_midSleep,
    sleepDuration,
    correctedMidSleep,
    midSleep,
  });

  if (Helper.timeToMinutes(midSleep) < Helper.timeToMinutes([2, 1])) return 1;
  if (Helper.timeToMinutes(midSleep) < Helper.timeToMinutes([3, 1])) return 2;
  if (Helper.timeToMinutes(midSleep) < Helper.timeToMinutes([4, 1])) return 3;
  if (Helper.timeToMinutes(midSleep) < Helper.timeToMinutes([5, 1])) return 4;
  if (Helper.timeToMinutes(midSleep) < Helper.timeToMinutes([6, 1])) return 5;
  if (Helper.timeToMinutes(midSleep) < Helper.timeToMinutes([7, 1])) return 6;

  return 7;
}
